import React from "react"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import PrivateWithdraw from "components/ido/private-sale-withdraw-new"

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'

function getLibrary(provider) {
  return new Web3(provider)
}

const IdoArbitrumPage = (props) => {
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <GlobalStateProvider initialState={globalState}>
        <Layout privateSale currentChain={["42161"]}>
          <SEO/>
          <PrivateWithdraw {...props} />  
        </Layout>
      </GlobalStateProvider>
    </Web3ReactProvider>

  )
}

export default IdoArbitrumPage